import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Container from 'components/layout/container';
import FaqList from 'components/blocks/faq_list';
import Layout from 'components/layout';
import PageTitle from 'ui/page_title';
import SEO from 'components/seo';

const Faq = () => {
  // const data = useStaticQuery(graphql`
  //   {
  //     allSitePage(filter: {context: {type: {eq: "faq"}}}, sort: {fields: context___i, order: ASC}) {
  //       edges {
  //         node {
  //           context {
  //             excerpt
  //             title
  //           }
  //           path
  //         }
  //       }
  //     }
  //   }
  // `);

  const nodes = false /* data.allSitePage.edges */;

  return (
    <Layout pageType="faq">
      <SEO title="Частые вопросы" />

      <Container>
        <PageTitle title="Частые вопросы" />
      </Container>

      {nodes ? <FaqList list={nodes} /> : (
        <Container>
          <h2 style={{ textAlign: 'center', margin: '8rem 0', fontSize: '3rem' }}>
            Раздел заполняется
          </h2>
        </Container>
      )}
    </Layout>
  );
};

export default Faq;
